import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Card } from "../ui/Cards"
import { Container } from "../ui/Container"
import {
  Article,
  PostFooter,
  PostHeader,
  PostSubheaderLine,
  PostTitle,
  TagsLine,
} from "../ui/post"
import { PostCardList } from "../ui/post-list"
import { Button } from "../ui/Button"
import { SoftLink } from "../ui/SoftLink"

const PostCard = props => {
  const { frontmatter, fields } = props
  const { title, posted, tags } = frontmatter
  const { slug } = fields

  const url = `/blog` + slug

  return (
    <Card>
      <Article>
        <PostHeader>
          <PostTitle as="h2">
            <SoftLink to={url}>{title}</SoftLink>
          </PostTitle>
          <PostSubheaderLine posted={posted} />
        </PostHeader>
        {tags.length > 0 && (
          <PostFooter>
            <TagsLine tags={tags} />
          </PostFooter>
        )}
        <Button to={url}>READ MORE</Button>
      </Article>
    </Card>
  )
}

const BlogIndexPage = ({ data }) => {
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout>
      <SEO />
      <Container>
        <PostCardList as="main">
          {posts.map(edge => (
            <PostCard key={edge.node.id} {...edge.node} />
          ))}
        </PostCardList>
        <Button to="/blog/all">All Posts</Button>
      </Container>
    </Layout>
  )
}

export default BlogIndexPage

export const pageQuery = graphql`
  query RecentBlogPosts {
    allMarkdownRemark(
      sort: { fields: [frontmatter___posted], order: DESC }
      limit: 4
      filter: { fields: { sourceName: { eq: "posts" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            posted
            tags
          }
          fields {
            slug
          }
        }
      }
    }
    allTags: allMarkdownRemark(
      filter: { fields: { sourceName: { eq: "posts" } } }
    ) {
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }
    }
  }
`
